import { DefaultTheme } from "styled-components";

export const defaultTheme: DefaultTheme = {
  breakpoints: {
    desktop: "1800px",
    laptop: "1500px",
    tabletLan: "1100px",
    tabletPor: "820px",
    mobile: "480px",
  },
  colors: {
    blue: "rgba(21,1,237,1)",
    red: "rgba(212,2,3,1)",
    yellow: "rgba(241,202,4,1)",
    black: "rgba(36,36,42,1)",
    white: "rgba(255,255,255,1)",
  },
};
